
  import { computed, defineComponent, onMounted, ref } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { useAbility } from '@casl/vue';
  import { BankRatesObject } from '@/store/modules/BankRatesModule';

  export default defineComponent({
    name: 'bank-rates-editing',
    components: {
      ErrorMessage,
      Field,
      Form,
    },
    async setup() {
      const { t, te } = useI18n();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const { can } = useAbility();

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('bankRateUpdating'), [
          translate('banks'),
        ]);
      });

      const { data } = await store.dispatch(
        Actions.GET_BANK_RATE,
        route.params.id
      );

      const bankRate = ref<BankRatesObject>(data.data);

      const bankRateSchema = Yup.object().shape({
        notClientPercentage: Yup.number()
          .required(() => translate('NON_CLIENT_PERCENTAGE_IS_REQUIRED_FIELD'))
          .label('Non Custumer Percentage'),
        percentage: Yup.number()
          .required(() => translate('PERCENT_IS_REQUIRED_FIELD'))
          .label('Percentage'),
      });

      const onSubmitCreate = async (values) => {
        values.bankId = 1;
        values.notClientPercentage = Number(values.notClientPercentage);
        values.percentage = Number(values.percentage);
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        await store.dispatch(Actions.UPDATE_BANK_RATE, {
          id: bankRate.value?.id,
          data: values,
        });

        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_BANK_RATE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'bank-rates-listing' });
          });
        }
      };

      await store.dispatch(Actions.GET_BANK_RATES_ENUM_ALL);
      const banks = computed(() => store.getters.bankRatesList);
      const selectedRate = ref<number>(banks.value[0].repaymentPeriod);

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        bankRates: computed(() => store.getters.bankRatesList),
        onSubmitCreate,
        bankRate,
        submitButton,
        selectedRate,
        translate,
        bankRateSchema,
        goBack,
        can,
      };
    },
  });
